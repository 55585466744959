import React from 'react'
import LoadingOverlay from "react-loading-overlay";
import { Loader, Types } from "react-loaders";
import PropTypes from 'prop-types'



const BlockLoader = ({ loading, children }) => {
    return <LoadingOverlay styles={{
        overlay: (base) => ({
            ...base,
            background: "rgba(255,255,255,0.5)",
        }),
    }} active={loading} spinner={<Loader active type="ball-pulse" />} >
        {children}
    </LoadingOverlay>
}

BlockLoader.propTypes = {
    loading: PropTypes.bool
}

export default BlockLoader